<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Nouvelle Assurance </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" :class="{'disabled' : isPreDefined}" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'assuranceList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">
                        <div class="three fields">
                            <div class="required field" :class="{'error': $v.assurance.label.$error}">
                                <label>Nom de l'assurance</label>
                                <input type="text" placeholder="Nom de l'assurance" v-model.trim="$v.assurance.label.$model" v-uppercase>
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.assurance.label.$error">
                                    <p> <i class="info circle icon"></i> Nom de l'assurance est Obligatoire. </p>
                                </div>
                            </div>

                            <div class="required field" :class="{'error': $v.assurance.prefix.$error}">
                                <label>N° dossier prefix</label>
                                <input type="text" maxlength="3" placeholder="Nom de l'assurance" v-model.trim="$v.assurance.prefix.$model" v-uppercase>
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.assurance.prefix.$error">
                                    <p v-if="!$v.assurance.prefix.required"> <i class="info circle icon"></i> N° dossier prefix est Obligatoire. </p>
                                    <p v-if="!$v.assurance.prefix.minLength || !$v.assurance.prefix.maxLength">
                                        <i class="info circle icon"></i> N° dossier prefix doit comporter 3 caractères.
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label>Honoraire</label>
                                <input type="number" placeholder="Honoraire" v-model.trim="assurance.honoraire">
                            </div>

                        </div>

                        <div class="two fields">

                            <div class="field">
                                <label>Téléphone / Fax </label>
                                <input type="tel" placeholder="Téléphone / Fax" v-model="assurance.tel">
                            </div>

                            <div class="field">
                                <label>Email</label>
                                <input type="email" placeholder="Email" v-model="assurance.email" v-uppercase>
                            </div>

                        </div>

                        <div class="field">
                            <label>Adresse</label>
                            <textarea placeholder="Adresse" v-model="assurance.adresse" rows="3" v-uppercase> </textarea>
                        </div>

                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    export default {
        name: "assuranceCrud",
        props:{
            current_assurance: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              assurance: {
                  label: '',
                  prefix: '',
                  tel: '',
                  email: '',
                  adresse: '',
                  honoraire: 0
              },
              predefined_assurance: ['sanad', 'axa', 'atlanta']
          }
        },
        validations: {
            assurance: {
                label: {required},
                prefix: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(3)
                }
            }
        },
        methods:{
            save(){
                log('save assurance:', this.assurance);
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                let data = {
                    collection: this.$SHARED.collections.assurance,
                    query: this.assurance
                };
                if(this.isEdit){ // update
                    let assurance = JSON.parse(JSON.stringify(this.assurance));
                    delete assurance._id;
                    data.query = {
                        _id: this.assurance._id
                    };
                    data.update = {'$set': assurance}
                }
                let url = this.isEdit ? this.$SHARED.services.updateIfNotExist
                                        : this.$SHARED.services.insertIfNotExist;

                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'assuranceList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.assurance[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "L'assurance " + this.$SHARED.messages["7"]
                    }
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                if(this.isPreDefined){
                    return;
                }
                let data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {
                        _id: this.assurance._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'assuranceList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.assurance.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        },
        computed:{
            isPreDefined(){
                return _.includes(this.predefined_assurance, this.assurance._id);
            }
        },
        mounted() {
            if(this.current_assurance.hasOwnProperty('_id')){
                this.isEdit = true;
                this.assurance = Object.assign({}, this.assurance, this.current_assurance);
            }
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>